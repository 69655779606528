import styled from "styled-components";
import { space, color, layout, typography, position } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Article = styled.article`
  padding: 0;
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${position}
  position: relative;

  max-width: 863px;
  min-height: 200px;

  @media screen and (max-width: ${themeGet("breakpoints.xl")}) {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
    margin-top: 64px;
    margin-bottom: 64px;
    min-height: 100px;
  }

  @media screen and (max-width: ${themeGet("breakpoints.md")}) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  aside {
    position: absolute;
    top: 0;
    flex-direction: column;
    font-size: 18px;
    font-weight: 700;

    transition: right 300ms;

    a {
      max-height: 48px;
      img {
        margin-bottom: 0;
      }
    }


    @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
      position: initial;
      top: 0;
      right: 0;
      flex-direction: row;
      justify-content: space-between;
      margin: 64px auto 0;
    }

    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {

      a {
        max-height: 40px;
        img {
          max-height: 40px;
        }
      }
    }
  }

  ul, ol {

    font-size: 14px;
    line-height: 24px;
  }

  ol {
    padding-left: 15px;
  }

  ul {
    list-style-type: none;

    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        display: block;
        width: 8px;
        height: 8px;
        background-color: ${themeGet("colors.EYYellow.default")};
      }
    }
  }

  div[id^="buzzsprout-player"] {
    width: calc(100% + 62px);
    margin-left: 50%;
    transform: translateX(-50%);
  }

  div.gatsby-resp-iframe-wrapper {
    width: calc(100% + 62px);
    margin-left: 50%;
    transform: translateX(-50%);

    iframe.black-white {
      filter: grayscale(1) brightness(.6);
    }

    .play-btn-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      height: 7vw;
      width: 7vw;
      min-height: 48px;
      min-width: 48px;
      max-height: 72px;
      max-width: 72px;

      border: none;
      outline: none;

      background: ${themeGet("colors.EYYellow.default")};

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 2vw;
        height: 2vw;
        min-width: 12px;
        min-height: 12px;
        max-width: 18px;
        max-height: 18px;
      }
    }
  }

  & > p:first-of-type {
    position: relative;
    &::first-letter {
      float:left;
      font-size: 56px;
      line-height: 60px;
      margin-right: 10px;
      text-transform: uppercase;
    }
  }

  & > div, & {
    & > p {
      line-height: 30px;
    }
  }

  & > p, & > ul, & > ol, & > h1, & > h2, & > h3, & > h4, & > h5, & > h6, & > div[id="cookie-table-declaration"] {
    width: 687px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    color: ${themeGet("colors.EYOffBlack.text")};
    font-weight: 300;

    strong {
      font-weight: 600;
    }

    a {
      text-decoration: underline;
      color: ${themeGet("colors.EYOffBlack.text")};
      &:link, &:visited {
        color: ${themeGet("colors.EYOffBlack.text")};
      }

      &:hover {
        text-decoration: none;
      }
    }

    img {
      display: block;
      margin-left: 50%;
      width: 90vw;
      max-width: 863px;
      transform: translateX(-50%);

      @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
        width: calc(100% + 62px);
      }
    }
  }

  & > h2 {
    font-size: 24px;
    line-height: 50px;
    font-weight: 400;
    color: ${themeGet("colors.EYOffBlack.default")};

    @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
      font-size: 18px;
      line-height: 24px;
    }
  }


`;

Article.displayName = `Article`;

Article.defaultProps = {
  fontSize: 2,
  lineHeight: 4,
  fontWeight: "normal",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "120px",
  marginBottom: "100px",
};

Article.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
};

export { Article };
