import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import GdprContent from "../components/GdprContent";

import { Link } from "../style/Link";
import usePageView from "../utils/usePageView";

const GdprPage = ({ data, location }) => {
  usePageView(location.pathname, "Osobní údaje", "");

  const { frontmatter } = data.gdprPageData.edges[0].node;

  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.metaTitle}
        description={frontmatter.metaDescription}
      />

      <GdprContent
        post={{
          frontmatter,
        }}
        image={frontmatter.headerImage.image}
        location={location}
        StyledLink={Link}
      />
    </Layout>
  );
};

export const query = graphql`
  query GdprPageQuery {
    gdprPageData: allMdx(
      filter: { frontmatter: { templateKey: { eq: "gdpr-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            metaTitle
            metaDescription
            headerImage {
              image {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 90)
                }
              }
              imageAlt
            }
            title
            content
          }
        }
      }
    }
  }
`;

export default GdprPage;
