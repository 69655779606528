import React, {useEffect} from "react";
import showdown from "showdown";

import Header from "../Header";
import TriangleGdpr from "../../style/Header/triangleGdpr";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Wrapper } from "../../style/Grid";
import { Article } from "../../style/Article";
import Container from "../../style/Grid/Container";

const converter = new showdown.Converter();

const GdprContent = ({ post, image }) => {
  useEffect(() => {
    const ns = document.createElement('script');
    ns.id = "CookieDeclaration"
    ns.src = "https://consent.cookiebot.com/ee1f3406-cb70-4007-97ed-cc03d3c5ef93/cd.js";
    ns.async = true;
    document.querySelector('#cookie-table-declaration').appendChild(ns);
  }, []);

  return (
    <>
      <Header
        bgImage={image}
        minHeight={["490px", null, null, "611px", null]}
        bottomComponents={<TriangleGdpr/>}
        containerJustifyContent="flex-end"
        shadowAfter={true}
        hideAfterMobile={true}
        css={{
          ":after": {
            backgroundImage:
              "linear-gradient(4.2deg, #2e2e38 0%, rgba(91, 91, 105, 0) 100%)"
          }
        }}
      >
        <>
          <Heading
            as="h1"
            color="white.100"
            mb={[3, 4, null, null, 3]}
            fontSize={[5, null, null, 7, null]}
            fontWeight="light"
            lineHeight="2"
            maxWidth={["100%", null, "460px", null, null]}
            backgroundPosition="center center"
          >
            {post.frontmatter.title}
          </Heading>
          <Text
            color="white.100"
            fontSize="2"
            mb={[4, 5, null, null, 6]}
            maxWidth={["100%", null, "630px", null, null]}
          >
            {post.frontmatter.headerImage.imageAlt}
          </Text>
        </>
      </Header>
      <Wrapper mb="20px">
        <Container>
          <Article
            width={["100%", "91%", null, null, "91%"]}
            marginTop={["20px", null, null, "60px", null]}
            marginBottom={[0, null, null, "30px", null]}
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(post.frontmatter.content)?.replace('<p>[COOKIE_TABLE]</p>', '<div id="cookie-table-declaration"></div>')
            }}
          />
        </Container>
      </Wrapper>
    </>
  );
}

export default GdprContent;
